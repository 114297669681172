<template>
<div class="container pt-2">
  <div class="block" v-show="editable">
    <el-date-picker
      class="mb-1"
      format="MM-dd-yyyy"
      v-model="date"
      :editable="false"
      @change="combineDateTime"
      type="date"
      placeholder="Select date">
    </el-date-picker>
    <el-time-select
      v-model="time"
      :editable="false"
      @change="combineDateTime"
      format="HH:mm a"
      :picker-options="{
        start: '00:00',
        step: '00:30',
        end: '23:30'
      }"
      placeholder="Select time">
    </el-time-select>

    <el-select v-model="form.video_uuid" placeholder="Select Video">
      <el-option
        v-for="item in videoResults"
        :key="item.uuid"
        :label="item.name"
        :value="item.uuid">
      </el-option>
    </el-select>

    <div class="float-right">
      <el-button
        @click="scheduleFilm"
        type="primary"
        :loading="isLoading"
        :disabled="!form.video_uuid">
        Schedule
      </el-button>
    </div>
    <hr>
  </div>

  <el-table
    v-loading.body="isLoading"
    :data="calendar"
    style="width: 100%">
    <el-table-column
      prop="start_time_local"
      label="Start Time"
      width="225">
    </el-table-column>
    <el-table-column
      prop="video.data.name"
      label="Name"
      width="150">
      <template slot-scope="scope">
        <a :href="'/festivals/'+festival.uuid+'/theater/'+calendar[scope.$index].theater.data.uuid">
          {{calendar[scope.$index].video.data.name}}
        </a>
      </template>
    </el-table-column>
    <el-table-column
      fixed="right"
      label=""
      width="65">
      <template slot-scope="scope">
        <el-button @click="handleDelete(scope.$index, calendar)" v-show="editable" size="small" icon="delete"></el-button>
      </template>
    </el-table-column>
  </el-table>
</div>
</template>

<script>
  export default {
    props: {
      uuid: {
        type: String,
        required: true
      },
      isAdmin: {
        type: Boolean,
        required: true
      }
    },
    data () {
      return {
        editable: false,
        festival: {},
        date: null,
        time: null,
        form: {
          start_date: null,
          video_uuid: null
        },
        calendar: [],
        result: [],
        videoResults: [],
        btnState: false,
        isLoading: false
      }
    },
    created () {
      this.fetchFestival()
      this.fetchVideos()
      this.fetchCalendar()

      var now = moment()
      var rounded = this.round(now, moment.duration(30, "minutes"), "ceil");
      this.form.start_date = rounded.format('YYYY-MM-DD HH:mm')
      this.date = rounded.format()
      this.time = rounded.format('HH:mm')
    },
    methods: {
      fetchVideos (query) {
        this.isLoading = true
        axios.get('/api/festivals/' + this.uuid + '/videos')
          .then(response => {
            this.videoResults = response.data.data
            this.isLoading = false
          })
      },
      fetchFestival (query) {
        this.isLoading = true
        axios.get('/api/festivals/' + this.uuid)
          .then(response => {
            this.festival = response.data.data
            var festival_start = moment(this.festival.start_date)
            this.date = festival_start.format()
            this.time = festival_start.format('HH:mm')
            this.editable = this.festival.is_owner || this.isAdmin
            this.isLoading = false
          })
      },
      fetchCalendar (query) {
        this.isLoading = true
        axios.get('/api/festivals/' + this.uuid + '/calendar')
          .then(response => {
            this.calendar = response.data.data
            this.isLoading = false
          })
      },
      scheduleFilm () {
        this.btnState = 'btn-loading'
        axios.put('/api/festivals/' + this.uuid + '/calendar', this.form).then(response => {
          this.btnState = 'btn-success'
          this.fetchCalendar()
          this.form.video_uuid = ''
        }, response => {
          this.errors = response.data
          this.btnState = 'btn-danger'
        })
      },
      handleDelete (index, rows) {
        var calEvent = rows[index]
        axios.delete('/api/festivals/' + calEvent.uuid + '/calendar').then(response => {
          this.fetchCalendar()
        }, response => {
          //
        })
      },
      hideErrors () {
        this.errors = false
        this.btnState = ''
      },
      combineDateTime () {
        this.form.start_date = moment(this.date).format('YYYY-MM-DD')+' '+this.time
      },
      round (date, duration, method) {
        return moment(Math[method]((+date) / (+duration)) * (+duration));
      }
    }
  }
</script>
