<template>
  <div>
    <div class="container mt-3 card text-center" v-if="admissionStats">
      <div class="row text-center">
        <div class="col-3" v-for="(stat, key) in admissionStats"
          style="border-right: 1px solid lightgrey;
          height: 80px;">
          <p class="lead pt-4 hidden-sm-down">
            <i v-if="key === 'Total Tickets Sold'"
              class="fa fa-ticket"></i>
            <i v-else-if="key === 'Sum'"
              class="fa fa-money"></i>
            {{key}}: 
            <span v-if="key === 'Sum'">$</span>
            {{stat}}
          </p>
          <p class="hidden-sm-up">
            {{key}}: 
            <span v-if="key === 'Sum'">$</span>
            {{stat}}
          </p>
        </div>
      </div>
    </div>
    <div class="container pt-2 mt-2 card" id="statArea">
      <div class="row">
        <div class="col">
          <ul class="nav flex-column">
            <div class="btn-group nav-item mx-auto">
              <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{activeTab.name}}
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="#" v-for="tab in tabs"
                  @click.prevent="setActive(tab)">{{tab.name}}
                </a>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col">
          <bar-chart :uuid="uuid"></bar-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import results from './results'

export default {
  components: {
    'bar-chart': results
  },
  props: {
    uuid: {
      required: true,
      type: String
    }
  },
  activeTab: {},
  data () {
    return {
      admissionStats: null,
      tabs: [
        {
          name: 'Festival Views',
        },
        {
          name: 'Film Views',
        },
        {
          name: 'Film Count',
        },
        {
          name: 'Theater Views',
        },
        {
          name: 'Subscribers',
        }
      ],
      activeTab: {
        name: 'Festival Views',
      },
    }
  },
  created () {
    this.fetchAdmissionStats()
  },
  methods: {
    setActive (tab) {
      this.activeTab.name = tab.name
      bus.$emit('tabUpdate', tab.name)
    },
    fetchAdmissionStats () {
      axios.get('/api/festivals/'+this.uuid+'/admission-stats')
        .then((response) => {
          this.admissionStats = response.data.totals
        })
    }
  }
}
</script>
