<template>
  <div class="theater">
    <div class="video-container">
      <video id="video-player" preload="auto" class="video-js vjs-16-9" :poster="video.poster">

        <source :src="video.url" type='video/mp4'>
      </video>
    </div>
    <small v-if="isSafari()" class="d-flex justify-content-center text-muted">
      Video will be muted by default on Safari.
    </small>
  </div>
</template>

<script>
export default {
  props: ['video', 'start', 'controls', 'poster-only'],
  data() {
    return {
      player: null
    }
  },
  mounted() {
    this.$nextTick(function () {
      const start = this.start

      const isSafari = this.isSafari()

      this.player = videojs('video-player', {
        muted: isSafari,
        controls: true,
        autoplay: false,
        controlBar: {
          playToggle: false,
          volumePanel: true,
          currentTimeDisplay: false,
          timeDivider: false,
          durationDisplay: false,
          progressControl: false,
          liveDisplay: false,
          remainingTimeDisplay: true,
          customControlSpacer: true,
          playbackRateMenuButton: false,
          chaptersButton: false,
          descriptionsButton: false,
          subsCapsButton: false,
          audioTrackButton: false,
          fullscreenToggle: true
        }
      }, function () {
        this.currentTime(start)
        this.play()

        if (isSafari) {
          setTimeout(() => {
            this.currentTime(start)
          }, 5000)
        }
      })
    })
  },

  methods: {
    isSafari () {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    }
  }
}
</script>
