<template>
    <div class="container">
        <div class="card my-3">
            <h3 class="card-header">Join a Live Theater</h3>
            <div class="card-body">
                <p class="card-text" v-if="!nowPlaying(theaters).length">You have no invitations pending.</p>
                <table class="table" v-if="nowPlaying(theaters).length">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Join</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(t, index) in nowPlaying(theaters)">
                      <td>{{ t.theater.name }}</td>
                      <td>{{ t.theater.description }}</td>
                      <td><a :href="t.link" class="btn btn-primary"><i class="fa fa-users" aria-hidden="true"></i> Join</a></td>
                    </tr>
                  </tbody>
                </table>
            </div>
        </div>
        <div class="card">
            <h3 class="card-header">Playing Soon</h3>
            <div class="card-body">
                <p class="card-text" v-if="!playingSoon(theaters).length">You have no invitations pending.</p>
                <table class="table" v-if="playingSoon(theaters).length">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Join</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(t, index) in playingSoon(theaters)">
                      <td>{{ t.theater.name }}</td>
                      <td>{{ t.theater.description }}</td>
                      <td><a :href="t.link" class="btn btn-primary"><i class="fa fa-users" aria-hidden="true"></i> Join</a></td>
                    </tr>
                  </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        created() {
            this.getTheaters()
        },

        data() {
            return {
                error: false,
                theaters: []
            }
        },

        methods: {
            getTheaters() {
                axios.get('/api/theaters?limit=100')
                  .then((response) => {
                    this.theaters = response.body.data
                  })
                  .catch((error) => {
                    this.error = true
                  })
            },
            playingSoon () {
                return this.sortedTheaters.filter(function(theater) {
                    if (theater.time < 0) {
                        return true
                    }
                })
            },
            nowPlaying () {
                return this.sortedTheaters.filter(function(theater) {
                    if (theater.video != undefined && theater.time >= 0 && theater.time < theater.video.length) {
                        return true
                    }
                })
            }



        },

        computed: {
            videoTime() {
                return this.theaters.map(function(theater) {
                    return {
                        theater: theater,
                        link: '/theaters/' + theater.uuid,
                        video: theater.video,
                        time: moment().diff(moment(theater.start), 'seconds')
                    }
                })

            },

            sortedTheaters () {
                return this.videoTime.sort(function(a, b){return b.time - a.time});
            }
        }
    }
</script>
