<template>
  <div>
    <div id="filmUploadSuccess"
      v-show="successMessage"
      class="alert alert-success alert-dismissible mb-0">
      <button type="button" class="close" data-dismiss="alert">
        <span>&times;</span>
      </button>
      <div class="mb-0">
        <p class="mb-0">
          <strong>Success:</strong>
          {{ successMessage }}
        </p>
        <a :href="filmStatusUrl">Click to view status</a>
      </div>
    </div>
    <div if="filmUploadError"
      v-if="errorMessage"
      class="alert alert-danger alert-dismissible">
      <button type="button" class="close" data-dismiss="alert">
        <span>&times;</span>
      </button>
      <strong>Error:</strong> There was an error with this upload.
    </div>
    <div class="container">

    <h1 class="pb-1">Choose Film</h1>
    <div class="row">
      <div class="mb-2 col-md-6">
        <multiselect
          style="z-index: 9999;"
          v-model="selectedVideo"
          id="ajax"
          track-by="uuid"
          :loading="isLoading"
          :options="videoResults"
          :close-on-select="true"
          label="name"
          placeholder="Type To Search Videos ... "
          @search-change="asyncFind">
          <span slot="noResult">
            Oops! No elements found. Consider changing the search query.
          </span>
          <template slot="option" slot-scope="props">
            <img class="option__image" :src="'/img/'+props.option.thumbnail_url" alt="Thumbnail">
            <div class="option__desc">
              <span class="option__title">{{ props.option.name }}</span>
            </div>
          </template>
        </multiselect>
      </div>
      <div class="col-md-6">
        <button @click.stop="attachFilm" class="btn btn-primary btn-block">Submit Film</button>
      </div>
    </div>
  </form>
</div>
</div>
</template>

<script>
  import multiselect from 'vue-multiselect'
  export default {
    props: {
      festivalUuid: {
        required: true,
        type: String
      }
    },
    data () {
      return {
        errorMessage: false,
        successMessage: false,
        emailUploadersURL: '/festivals/' + this.festivalUuid + '/email-uploaders',
        selectedVideo: {
          uuid: ''
        },
        attachedVideo: '',
        videoResults: [],
        isLoading: false,
      }
    },
    components: {
      multiselect
    },
    computed: {
      filmStatusUrl: function () {
        return '/festivals/' + this.festivalUuid + '/' + this.attachedVideo + '/status'
      },
      attachFilmUrl: function () {
        return '/festivals/' + this.festivalUuid + '/attach'
      }
    },
    watch: {
      attachedVideo: function () {
        this.asyncFind('')
      }
    },
    created () {
      this.asyncFind('')
    },
    methods: {
      asyncFind (query) {
        this.isLoading = true
        axios.get('/api/videos?search='+query+'&festival='+this.festivalUuid)
          .then((response) => {
            this.videoResults = response.data.data
            this.isLoading = false
          })
      },
      attachFilm () {
        axios.patch(this.attachFilmUrl, {
          video_uuid: this.selectedVideo.uuid
        }).then((response) => {
          this.successMessage = response.data.message
          this.attachedVideo = this.selectedVideo.uuid
          this.selectedVideo = {
            uuid: ''
          }
        }).catch((error) => {
          this.errorMessage = true
        })
      }
    }
  }
</script>
<style>
#filmUploadSuccess {
  width: 300px;
  position: fixed;
  top: 25px;
  left: 25px;
  z-index: 1000;
}
</style>
