<template>
  <div class="alert alert-info text-center">
    <div class="d-flex flex-row justify-content-around mt-3">
      <div>
        <p class="lead">Days</p>
        <strong>{{days}}</strong>
      </div>
      <div>
        <p class="lead">Hours</p>
        <strong>{{hours}}</strong>
      </div>
      <div>
        <p class="lead">Minutes</p>
        <strong>{{minutes}}</strong>
      </div>
      <div>
        <p class="lead">Seconds</p>
        <strong>{{seconds}}</strong>
      </div>
    </div>
    <div class="row mx-auto mt-2">
      <div class="col text-center">
        <strong>This theater is coming soon.</strong> Come back on {{ local }} or wait here till the theater begins.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created () {
    let date = Math.trunc(moment.utc(this.date).valueOf() / 1000)
    this.dateValue = date
    window.setInterval(() => {
      this.now = Math.trunc((new Date()).getTime() / 1000);
    },1000);
  },
  props : {
    local: {
      type: String,
      required: true
    },
    date : {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dateValue: '',
      now: Math.trunc((new Date()).getTime() / 1000)
    }
  },
  computed: {
    seconds() {
      return (this.dateValue - this.now) % 60;
    },
    minutes() {
      return Math.trunc(((this.dateValue - this.now)) / 60) % 60
    },
    hours() {
      return Math.trunc((this.dateValue - this.now) / 60 / 60) % 24;
    },
    days() {
      return Math.trunc((this.dateValue - this.now) / 60 / 60 / 24);
    }
  }
}
</script>
