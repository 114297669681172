<style>
.image-scroll-horizontal {
    overflow: auto;
    white-space: nowrap;
    width: 100%;
    max-height: 36vw;
}
.horizontal-spacer {
    display: inline-block;
    width: 20vw;
    color: white;
}
.scroll-item {
    display: inline-block;
    width: 60vw;
    height: 34vw;
    border-right: 3px solid black;
    color: white;
}
.scroll-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.img-overlay {
    position: relative;
    top: -3rem;
    padding: 0.5rem;
    background-color: rgba(0,0,0,0.5)
}
</style>

<template>
    <div>
        <div class="image-scroll-horizontal">
            <i v-show="loading" class="fa fa-spin fa-spinner fa-5x mb-3"></i>
            <div v-show="!loading">

                <div class="horizontal-spacer"></div>

                <div v-for="category in categories"
                    class="scroll-item">
                    <img class="scroll-img" :src="category.image_url"><img>
                    <div class="img-overlay">
                        <h3><b>{{category.name}}</b></h3>
                    </div>
                </div>

                <div class="horizontal-spacer"></div>

            </div>
        </div>

        <div class="btn-group mx-auto mb-2 mt-1">
            <button @click="scrollPrevious()" class="px-3 btn btn-outline-secondary">
                <i class="fa fa-arrow-left"></i>
            </button>
            <button @click="scrollNext()" class="px-3 btn btn-outline-secondary">
                <i class="fa fa-arrow-right"></i>
            </button>
        </div>
    </div>
</template>

<script>

    export default {
        mounted: function() {
            this.getCategories()
            this.$nextTick(function () {
                this.scrollBox = document.querySelector('.image-scroll-horizontal')
            })
        },
            data: function() {
                return {
                    scrollBox: null,
                    categories: {},
                    loading: true,
                    error: false,
                }
            },
            methods: {
                imgWidth() {
                    return document.querySelector('.scroll-item').clientWidth
                },
                spacerWidth() {
                    return document.querySelector('horizontal-spacer').clientWidth
                },
                scrollWidth() {
                    return this.scrollBox.clientWidth
                },
                scrollPos() {
                    return this.scrollBox.scrollLeft
                },
                setScroll(position) {
                    return this.scrollBox.scrollLeft = position
                },
                getCategories() {
                    axios.get('/api/categories?featured=true').then(response => {
                        this.categories = response.data.data
                        this.loading = false
                    }, response => {
                            this.error = true
                    })
                },
                    scrollNext() {
                        var numImgWidths = Math.round(
                            this.scrollPos() / this.imgWidth()
                        )
                        var position = (numImgWidths + 1) * this.imgWidth()
                        this.setScroll(position)
                    },
                    scrollPrevious() {
                        var numImgWidths = Math.round(
                            this.scrollPos() / this.imgWidth()
                        )
                        var position = (numImgWidths - 1) * this.imgWidth()
                        this.setScroll(position)
                    },
            }
    }
    </script>
