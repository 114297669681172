<template>
  <div class="mt-1">
    <div class="row text-center">
      <div class="col-4 statTotalPanel" v-for="(total, key) in totals">
        <p class="lead pt-4">{{key}}: {{total}}</p>
      </div>
    </div>
    <bar-chart
      class="my-2"
      style="max-height: 400px !important;"
      :chart-data="dataset">
    </bar-chart>
    <div class="row text-center" style="border-top: 1px solid whitesmoke;">
      <p class="lead col-4 offset-4 mt-2">Visitors</p>
    </div>
      <pie-chart
        class="my-2"
        style="max-height: 400px !important;"
        :chartData="visitorDataset">
      </pie-chart>
  </div>
</template>
<script>
import barChart from './barChart.js'
import pieChart from './pieChart.js'
export default {
  props: {
    uuid: {
      required: true,
      type: String
    }
  },
  components: {
    'bar-chart': barChart,
    'pie-chart': pieChart,
  },
  data () {
    return {
      dataset: null,
      visitorDataset: null,
      stats: null,
      totals: {}
    }
  },
  created () {
    this.fetchStats('festival-views')
    this.fetchVisitorStats()
    bus.$on('tabUpdate', this.updateTab)
  },
  methods: {
    fetchVisitorStats () {
      axios.get('/api/festivals/'+this.uuid+'/visitor-stats')
        .then((response) => {
          this.visitorDataset = {
            labels: Object.keys(response.data), 
            datasets: [
              {
                pointBackgroundColor: 'white',
                borderWidth: 1,
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)'
                ],
                borderColor: [
                  'rgba(255,99,132,1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)'
                ],
                pointBorderColor: '#212733',
                data: Object.values(response.data)
              }
            ]
          }
        })
    },
    fetchStats (tab) {
      axios.get('/api/festivals/'+this.uuid+'/'+tab)
        .then((response) => {
          this.totals = response.data.totals
          delete response.data.totals
          this.dataset = {
            labels: Object.keys(response.data), 
            datasets: [
              {
                pointBackgroundColor: 'white',
                borderWidth: 1,
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)'
                ],
                borderColor: [
                  'rgba(255,99,132,1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)'
                ],
                pointBorderColor: '#212733',
                data: Object.values(response.data)
              }
            ]
          }        
        })
    },
    updateTab (tab) {
      switch (tab) {
        case "Festival Views":
          this.stats = 'festival-views'
          break
        case "Film Views":
          this.stats = 'film-views'
          break
        case "Theater Views":
          this.stats = 'theater-views'
          break
        case "Subscribers":
          this.stats = 'subscribers'
          break
        case "Film Count":
          this.stats = 'film-count'
      }
      this.fetchStats(this.stats)
    }
  }
}
</script>
<style scoped>
.statTotalPanel {
  border-top: 1px solid whitesmoke;
  border-bottom: 1px solid whitesmoke;
  height: 75px;
}
.visitorPanel {
  border-top: 1px solid whitesmoke;
  height: 500px;
}
</style>
