<template>
  <div>
    <input hidden :name="name" :value="result">

    <el-date-picker
      v-model="date"
      @change="update"
      format="MM-dd-yyyy"
      type="datetime"
      placeholder="Select date and time">
    </el-date-picker>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    props: {
      value: {
        required: true,
        type: String
      },
      name: {
        required: true,
        type: String
      }
    },

    data () {
      return {
        date: '',
        result: ''
      }
    },

    created () {
      this.date = this.value ? moment(this.value).toDate() : new Date()
      this.update()
    },

    methods: {
      update () {
        this.result = moment(this.date).format('YYYY-MM-DD hh:mm:ss')
      }
    }
  }
</script>
