/* global Vue */

/**
 * First we will load all of this project's JavaScript dependencies which
 * include Vue and Vue Resource. This gives a great starting point for
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap')

/*
 * Event Bus
 */

window.bus = new Vue()

// Indevue components
Vue.component('theater-index', require('./theater/index.vue').default)
Vue.component('theater-create', require('./theater/create.vue').default)
Vue.component('submit-video', require('./festivals/submitVideo.vue').default)
Vue.component('theater-show', require('./theater/show.vue').default)

Vue.component('multiselecter', require('./components/multiselecter.vue').default)
Vue.component('datepicker', require('./components/datepicker.vue').default)
Vue.component('datetimepicker', require('./components/datetimepicker.vue').default)
Vue.component('colorpicker', require('./components/colorpicker.vue').default)
Vue.component('tagger', require('./components/tagger.vue').default)
Vue.component('timezonepicker', require('./components/timezonepicker.vue').default)

Vue.component('video-create', require('./video/create.vue').default)

Vue.component('category-slider', require('./homepage/category-slider.vue').default)

Vue.component('card-form', require('./components/users/cardForm.vue').default)

// Festival
Vue.component('festival-calendar', require('./festivals/calendar.vue').default)
Vue.component('festival-submissions', require('./festivals/submissions.vue').default)
Vue.component('festival-stats', require('./festivals/stats/index.vue').default)

Vue.component('video-carousel', require('./components/festivals/videoCarousel.vue').default)

/*
 * Element-Ui
 */

import 'element-ui/lib/theme-default/index.css'
import ElementUI from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

locale.use(lang)

Vue.use(ElementUI)

const app = new Vue({
  el: '#app'
})
