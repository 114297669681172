<template>
  <div class="container pt-2">
    <div class="card">
      <div class="card-header">
        <h3 class="text-center">
          Create a Theater
        </h3>
        <a href="/videos/create" id="uploadTheaterBtn">
          <button id="uploadButton" type="button" class="btn btn-outline-success">
            <i class="fa fa-cloud-upload"></i><span style="color: black;"> Upload</span>
          </button>
        </a>
      </div>
      <div class="card-body">
        <p v-show="!isUser">You must be logged in to Create a Theater. <a href="/login">Click here</a> to login.</p>
        <label class="col-form-label">Video</label>
        <multiselect
          v-model="selectedVideo"
          id="ajax"
          track-by="uuid"
          :loading="isLoading"
          :options="videoResults"
          :close-on-select="true"
          :custom-label="customLabel"
          label="name"
          placeholder="Type To Search Videos ... "
          @input="handleFormChange"
          @search-change="asyncFind">
          <span slot="noResult">
            Oops! No elements found. Consider changing the search query.
          </span>
        </multiselect>
        <div class="form-group">
          <label class="col-form-label">Name</label>
          <input
            v-model='theater.name'
            @change='hideErrors'
            type="text"
            class="form-control">
          <small class="form-text text-muted">
            If no name is entered, the video name will be used.
          </small>
        </div>
        <div class="form-group">
          <label class="col-form-label">Description</label>
          <input
            v-model='theater.description'
            @change='hideErrors'
            type="text-area"
            class="form-control">
          <small class="form-text text-muted">
            If no description is entered, the video description will be used.
          </small>
        </div>
        <div class="form-group row">
          <div class="col-sm-12 col-lg-6">
            <el-select
              style="width: 100%;"
              v-model="theater.timezone"
              clearable
              filterable
              remote
              placeholder="Please enter a timezone"
              @change="update"
              @clear="fetchTimezones"
              :remote-method="fetchTimezones"
              :loading="loading">
              <el-option
                v-for="item in timezoneOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <small class="form-text text-muted">
              Timezone
            </small>
          </div>
          <div class="col-sm-12 col-lg-3">
            <el-date-picker
              format="MM-dd-yyyy"
              v-model="startDate"
              type="date"
              @selected="hideErrors"
              placeholder="Select Date">
            </el-date-picker>
            <small class="form-text text-muted">
              Start Date
            </small>
          </div>
          <div class="col-sm-12 col-lg-3">
            <el-time-select
              placeholder="Start time"
              v-model="startTime"
              format="HH:mm a"
              :picker-options="{
                start: '00:00',
                step: '00:15',
                end: '23:45'
              }">
            </el-time-select>
            <small class="form-text text-muted">
              Start Time
            </small>
          </div>
        </div>
        <span @click="create()">
          <anibutt
            :btnState="btnState"
            btnClass="btn-block btn-primary">
            Create
          </anibutt>
        </span>
        <div
          v-show="errors"
          :class="{
            'alert-success':!errors,
            'alert-danger':errors
          }"
          class="mt-1 alert">
          {{errorMessage}}
        </div>
      </div>
    </div>
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
  import multiselect from 'vue-multiselect'
  import anibutt from '../utilities/anibutt.vue'
  export default {
    props: ['video', 'authCheck'],

    data () {
      return {
        selectedVideo: {
          name: '',
          uuid: '',
          user: {
            data: {
              name: ''
            }
          }
        },
        result: [],
        videoResults: [],
        timezone: '',
        timezoneOptions: [],
        loading: false,
        isLoading: false,
        theater: {
          video_uuid: this.video_uuid,
          timezone: ''
        },
        disabled: {
          to: moment().subtract(1, 'days').toDate()
        },
        startDate: moment().format(),
        startTime: '',
        errors: false,
        errorMessage: '',
        btnState: '',
      }
    },
    components: {
      multiselect,
      anibutt
    },
    mounted () {
      this.$Progress.finish()
      this.theater.timezone = moment.tz.guess()
    },
    created () {
      this.$Progress.start()
      if (this.video === '' || this.video === undefined || this.video == null) {
        this.result = null
      } else {
        this.result = JSON.parse(this.video)
        this.selectedVideo.name = this.result.name
        this.theater.video_uuid = this.result.uuid
        this.selectedVideo.uuid = this.result.uuid
        this.selectedVideo.user.data.name = this.result.user.name
      }
      var now = moment()
      var nextThirty = this.round(now, moment.duration(30, "minutes"), "ceil")
      this.startTime = nextThirty.format('HH:mm')
    },
    computed: {
      datetimeStart () {
        return moment(this.startDate).format('YYYY-MM-DD') + ' ' + this.startTime
      },
      isUser() {
        return (window.User != "undefined");
      }
    },
    methods: {
      update () {
        this.value = this.timezone
      },
      handleFormChange () {
        this.theater.video_uuid = this.selectedVideo.uuid || null
        this.hideErrors()
      },
      customLabel ({ name, user }) {
        if (name === '' && user.data.name === '') {
          return ` Type To Start Searching...`
        } else {
          return ` ${name} - ${user.data.name}`
        }
      },
      asyncFind (query) {
        this.isLoading = true
        axios.get('/api/videos?q=' + query)
          .then(response => {
            this.videoResults = response.data.data
            this.isLoading = false
          })
      },
      hideErrors () {
        this.errors = false
        this.btnState = ''
      },
      create () {
        this.btnState = 'btn-loading'
        this.theater.start = this.datetimeStart
        axios.post('/api/theaters', this.theater)
          .then(response => {
            if (response.data.uuid) {
              window.location = '/theaters/' + response.data.uuid
            }
          },
          function (response) {
            this.btnState = 'btn-danger'
            if (response.status === 404) {
              this.errorMessage = 'Video not found.'
            } else {
              this.errorMessage = 'There was a problem with your input.'
            }
            this.errors = response.data
          })
      },
      fetchTimezones (query) {
        this.loading = true
        axios.get('/api/timezones?q=' + query)
          .then(response => {
            this.loading = false
            this.timezoneOptions = response.data.data
          })
      },
      round (date, duration, method) {
        return moment(Math[method]((+date) / (+duration)) * (+duration));
      }
    }
  }
</script>
