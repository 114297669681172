<template>
  <div class="container submissions">
    <div class="d-flex flex-row justify-content-center m-3">
      <div class="dropdown">
        <h4>Submissions</h4>
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{status}}
        </button>
        <div class="dropdown-menu">
          <a class="dropdown-item" v-for="option in statusOptions"
            @click.prevent="setStatus(option)" href="#">
            {{option}}
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <table class="table" v-if="films.length">
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Length</th>
              <th>User</th>
              <th>Submitted On</th>
              <th>Approve/Deny/Disqualify</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, key) in films">
              <td> {{key+1}} </td>
              <td><a :href="'/videos/'+value.uuid">{{value.name}}</a></td>
              <td>{{ Math.round(value.length / 60) }} min</td>
              <td>
                <a :href="'/users/'+value.user.uuid">{{value.user.name}}</a>
                <br>
                <a :href="'mailto:'+value.user.email">{{ value.user.email }}</a>
              </td>
              <td>
                <span :title="value.created_at">
                  {{ fromNow(value.created_at) }}
                </span>
              </td>
              <td class="statusUpdates">
                <button class="btn btn-outline-success"
                  :class="[status === 'approved' ? 'disabled' : '']"
                  @click="updateFilmStatus(value, 'approve')">
                  <i class="fa fa-check"></i>
                </button>
                <button class="btn btn-outline-danger"
                  :class="[status === 'denied' ? 'disabled' : '']"
                  @click="updateFilmStatus(value, 'deny')">
                  <i class="fa fa-ban"></i>
                </button>
                <button class="btn btn-outline-warning"
                  :class="[status === 'disqualified' ? 'disabled' : '']"
                  @click="updateFilmStatus(value, 'disqualify')">
                  <i class="fa fa-exclamation-triangle"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="alert alert-info mx-auto text-center">
          No {{status}} submissions at this time.
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    uuid: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      error: {},
      success: {},
      videos: {},
      films: [],
      status: 'pending',
      selectedFilm: {},
      statusOptions: [
        'approved',
        'denied',
        'disqualified',
        'pending'
      ]
    }
  },

  created () {
    this.querySubmissions()
  },
  methods: {
    setStatus (option) {
      this.status = option
      this.querySubmissions()
    },
    querySubmissions () {
      axios.get('/api/festivals/'+this.uuid+'?include=films')
        .then(function (response) {
          this.parseVideos(response.data.data.films.data)
        }.bind(this))
    },
    parseVideos (val) {
      const status = this.status
      this.films = val[status]
    },
    updateFilmStatus(val, status) {
      axios.post('/festivals/'+this.uuid+'/'+status+'-film', {
        video_uuid: val.uuid
      }).then((res) => {
        (new PNotify({
          title: res.data.message,
          styling: "bootstrap3",
          hide: true,
          type: 'success',
          delay: 2000,
        }))
        this.querySubmissions()
      })
    },
    fromNow(date) {
      return moment(date).fromNow();
    }
  }
}

</script>
