<template>
  <div>
    <input hidden :name="name" :value="value">

    <el-color-picker @change="update" v-model="color"></el-color-picker>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        required: true,
        type: String
      },
      name: {
        required: true,
        type: String
      }
    },

    data () {
      return {
        color: null
      }
    },

    created () {
      this.color = this.value || '#000000'
    },

    methods: {
      update () {
        this.value = this.color
      }
    }
  }
</script>
