<template>
  <div>
    <input hidden :value="result" :name="name">

    <form @keydown.enter.prevent="add" @keyup.188="add">
    <el-input
        icon="plus"
        placeholder="Enter a comma to seperate tags"
        v-model="newTag"
        :on-icon-click="add"
      ></el-input>
  </form>

    <el-tag
      closable
      style="margin-top: 0.25rem; margin-right: 0.25rem"
      type="gray"
      @close="remove(tag)"
      v-for="tag in values"
      :key="tag">
      {{ tag }}
    </el-tag>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: String,
        required: true
      },
      name: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        newTag: '',
        values: [],
        result: ''
      }
    },

    created () {
      this.result = this.value
      this.values = this.value ? this.value.split(/\s*,\s*/) : []
    },

    methods: {
      remove (tag) {
        const tags = this.values.concat().filter((t) => {
          return t !== tag
        })

        this.result = tags.join(',')
        this.values = tags.concat()
      },

      add () {
        const tag = this.newTag.replace(/($\s+|\s*,)/g, '')
        if (this.result.indexOf(tag) === -1) {
          this.values = this.values.concat(tag)
          this.result = this.values.join(',')
        }
        this.newTag = ''
      }
    }
  }
</script>
