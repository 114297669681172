<template>
  <div class="container-fluid">
    <h1 class="text-center py-1">MOBVUE</h1>
    <div v-if="error">
      <div class="alert alert-danger">Theater not found</div>
    </div>
    <div v-else>
      <div v-if="!loading" class="row">
        <div class="col-md-8">
          <div class="card mb-3">

            <!-- coming soon / ended / video content -->
            <div class="card-top" v-if="nowPlaying">
              <video-player :video="theater.video" :start="videoTime"></video-player>
            </div>
            <img v-else width="100%" class="card-img-top" :src="theater.video.poster"></img>

            <!-- alert for coming soon or ended theaters -->
            <div v-if="comingSoon">
              <countdown :date="theater.start" :local="theater.start_local_readable"></countdown>
            </div>
            <div v-if="ended" class="m-1 alert alert-info" role="alert">
              <strong>This theater has ended.</strong> View the original video <a :href="videoLink">here</a>.
            </div>

            <!-- Details -->
            <div class="card-body">
              <div class="card-title">
                <h4>{{theater.name}}</h4>
              </div>
              <div class="card-text">
                <p v-html="nl2br(theater.description)"></p>
              </div>
              <div v-if="!ended" class="form-group">
                <label>Share link</label>
                <div class="input-group">
                  <input readonly class="form-control" type="text" v-model="theaterLink" id="theater-link"></input>
                  <span class="input-group-btn">
                    <button class="btn btn-secondary" id="copy-btn" data-clipboard-target="#theater-link">
                      <i class="fa fa-copy"></i>
                    </button>
                    <a :href="mailLink" class="btn btn-secondary">@</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <chatroom @newChat="getChats()" :auth_check="auth_check" :enabled="nowPlaying" :theater_uuid="theater.uuid" :chats="theater.chats"></chatroom>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chatroom from './chatroom.vue'
import videoPlayer from '../video/player.vue'
import countdown from './countdown.vue'
import nl2br from 'locutus/php/strings/nl2br'
new Clipboard('#copy-btn')

export default {
  props: ['auth_check'],
  //TODO add events that refresh the page when theater begins or ends
  created() {
    var locArray = window.location.pathname.split('/')
    this.theater.uuid = locArray[locArray.length - 1]
    this.getTheater()
  },
  data() {
    return {
      loading: true,
      comingSoon: false,
      nowPlaying: false,
      ended: false,
      error: false,
      theaterLink: window.location.href,
      theater: {
        uuid: null,
        video: {
          poster: null
        },
        chats: []
      }
    }
  },
  components: {
    chatroom,
    videoPlayer,
    countdown
  },
  computed: {
    videoTime() {
      return moment().diff(moment.utc(this.theater.start), 'seconds')
    },
    mailLink() {
      return "mailto:?subject=Join%20me%20at%20Indevue%20Theaters&body=" + window.location.href
    },
    videoLink() {
      return '/videos/' + this.theater.video.uuid
    }
  },
  methods: {
    setTheaterState() {
      if (this.videoTime < 0) {
        this.comingSoon = true
        this.nowPlaying = false
        this.ended = false
      } else if (this.videoTime < this.theater.video.length) {
        this.nowPlaying = true
        this.comingSoon = false
        this.ended = false
      } else {
        this.ended = true
        this.comingSoon = false
        this.nowPlaying = false
      }
    },
    getChats() {
      axios.get('/api/theaters/' + this.theater.uuid)
        .then((response) => {
          this.theater.chats = response.data.chats
        })
        .catch((error) => {
          this.error = true
        })
    },
    getTheater() {
      axios.get('/api/theaters/' + this.theater.uuid)
        .then((response) => {
          this.theater = response.data
          this.loading = false
          this.setTheaterState()
          this.setRefreshTimes()
        })
        .catch((error) => {
          this.error = true
        })
    },
    setRefreshTimes() {
      if (this.comingSoon) {
        var self = this
        setTimeout(function(){self.getTheater()}, this.videoTime*(-1000))
      }
    },
    nl2br(string) {
      return nl2br(string)
    }
  }
}
</script>
