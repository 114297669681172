import $ from 'jquery';
import PNotify from 'pnotify';
import Clipboard from 'clipboard'
import moment from 'moment-timezone'
import nl2vr from 'locutus/php/strings/nl2br'

window.$ = $
window.jQuery = $
window.PNotify = PNotify
window.Tether = require('tether')
window.Clipboard = Clipboard
window.moment = moment
window.Vue = require('vue')
window.axios = require('axios')
window.Pusher = require('pusher-js')
window.Dropzone = require('vue2-dropzone')
window.Dropzone.autoDiscover = false;

require('lodash')
require('bootstrap')
require('sweetalert')
require('vue-resource')
require('amcharts3/amcharts/amcharts')
require('amcharts3/amcharts/serial')
require('amcharts3/amcharts/pie')
require('select2/dist/js/select2.full')
require('image-picker')
require('../custom.js')

// God bless
$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})

// Pulse Loader
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

// Progress Bar
import VueProgressBar from 'vue-progressbar'

const options = {
  color: '#3c8dbc',
  failedColor: 'red',
  thickness: '3px',
  transition: {
    speed: '0.3s',
    opacity: '0.6s'
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}

Vue.use(VueProgressBar, options)

axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  config.headers['X-CSRF-TOKEN'] = window.app.csrfToken
  return config
})

axios.interceptors.response.use((response) => {
  return Promise.resolve(response)
}, (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      window.location = '/login?sessionExpired=true'
      return Promise.reject(error)
    }
  }

  return Promise.reject(error)
})

$.ajaxSetup({
  headers: {
    'X-CSRF-TOKEN': window.app.csrfToken
  }
})

Vue.mixin({
  created: function () {
    let spinner = document.getElementById('hide-the-spinner')
    if (spinner) {
      spinner.style.display = 'none'
    }
  }
})
/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */
import Echo from "laravel-echo"

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: app.pusherKey
})

//script.js async script loader
window.$script = require('scriptjs')
