<template>
    <div class="card">
        <div class="card-header">
            Chat
        </div>
        <div id="chatroom" @scroll="setScrolledUp()" class="card-body chatroom">
            <ul class="">
                <li v-for="chat in chats">
                    <b>{{chat.name}}:</b> {{ chat.message }}
                </li>
            </ul>
        </div>
        <div v-if="enabled" class="card-footer">
            <div v-if="!auth_check" class="form-group">
                <label>Name:</label>
                <input type="text" @mousedown="hideError()" v-model="chat.name" class="form-control"></input>
            </div>
            <div class="form-group">
                <label>Chat:</label>
                <textarea @keyup.enter="createChat()" @mousedown="hideError()"
                    v-model="chat.message" class="form-control"
                    id="exampleTextarea" rows="1"></textarea>
            </div>
            <div v-show="errors" class="alert alert-danger">
                There was a problem sending your chat.
            </div>
            <button @click="createChat()" class="btn btn-primary">Send</button>
        </div>
        <div v-else class="card-footer">
            <p class="card-text text-center">Chat is only available to live theaters</p>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['auth_check', 'chats', 'enabled', 'theater_uuid'],

            data() {
                return {
                    chatEl: null,
                    chat: {
                        message: null,
                    },
                    customname: null,
                    errors: false,
                    scrolledUp: false
                }
            },
            created() {
                this.chat.theater_uuid = this.theater_uuid
                this.setupEcho()
            },
            mounted() {
                this.$nextTick(function () {
                    this.chatEl = document.getElementById('chatroom')
                    this.scrollBottom()
                    this.$on('newChat', function() {
                        this.scrollBottom()
                    })
                })
            },
            methods: {
                createChat() {
                    if (this.customname != null) {
                        this.chat.name = this.customname
                    }
                    axios.post('/api/chats', this.chat).then(response => {
                        //send an event to reload chats
                        this.chat.message = null
                    }, response => {
                        this.errors = response
                    })
                },
                    setScrolledUp() {
                        this.scrolledUp = Math.floor(this.chatEl.scrollHeight - this.chatEl.scrollTop) > this.chatEl.clientHeight
                    },
                    scrollBottom() {
                        if (!this.scrolledUp) {
                            this.chatEl.scrollTop = this.chatEl.scrollHeight
                        }
                    },
                    hideError() {
                        this.errors = false
                    },
                    setupEcho() {
                        Echo.channel('theater.' + this.theater_uuid)
                            .listen('ChatCreated', (e) => {
                                this.$emit('newChat')
                            })
                    }
            }
    }
    </script>

