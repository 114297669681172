<template>
  <div>
    <input hidden :name="name" :value="result">
    <el-date-picker
      format="MM-dd-yyyy"
      class="mb-1"
      v-model="date"
      :editable="false"
      @change="combineDateTime"
      type="date"
      placeholder="Select date">
    </el-date-picker>
    <el-time-select
      v-model="time"
      :editable="false"
      @change="combineDateTime"
      format="HH:mm a"
      :picker-options="{
        start: '00:00',
        step: '00:30',
        end: '23:30'
      }"
      placeholder="Select time">
    </el-time-select>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    props: {
      value: {
        required: true,
        type: String
      },
      name: {
        required: true,
        type: String
      },
      timezone: {
        required: false,
        type: String
      }
    },

    data () {
      return {
        date: '',
        time: '',
        datetime: '',
        result: ''
      }
    },

    created () {
      this.parseDate()
    },

    methods: {
      update () {
        if (this.datetime) {
          this.result = moment(this.datetime).format('YYYY-MM-DD HH:mm:ss')
        } else {
          this.result = ''
        }
      },
      combineDateTime () {
        this.datetime = moment(this.date).format('YYYY-MM-DD')+' '+this.time
        this.update()
      },
      parseDate () {
        if (this.value && this.timezone) {
          var passedDate = moment(this.value).tz(this.timezone)
          this.datetime = passedDate.format('YYYY-MM-DD HH:mm')
          this.date = passedDate.format()
          this.time = passedDate.format('HH:mm')
        } else {
          var now = moment()
          var rounded = this.round(now, moment.duration(30, "minutes"), "ceil");
          this.datetime = rounded.format('YYYY-MM-DD HH:mm')
          this.date = rounded.format()
          this.time = rounded.format('HH:mm')
        }

        this.update()
      },
      round (date, duration, method) {
        return moment(Math[method]((+date) / (+duration)) * (+duration));
      }
    }
  }
</script>
