<template>
    <button @click="authWithDrive" class="btn btn-block btn-social btn-google">
        <slot></slot>
    </button>
</template>

<script>
    export default {
        props: {
            url: {
                type: String,
                    required: true,
            },
                developerKey: {
                    type: String,
                        required: true,
                },
                clientId: {
                    type: String,
                        required: true,
                },
                multiselect: {
                    type: Boolean,
                    default: false,
                },
        },
            data() {
                return {
                    uploadData: {},
                    oauthToken: null,
                    pickerApiLoaded: true,
                    authResult: {},
                }
            },

            created() {
                var vm = this
                bus.$on('videoCreated', function (event) {
                    if (event.method == 'drive') {
                        vm.upload('/api/videos/upload/' + event.uuid)
                    }
                })
                $script('https://apis.google.com/js/client.js', function() {
                    //vm.setupPicker()
                })
            },
            methods: {
                upload(url) {
                    var vm = this
                    this.uploadData.method = 'drive'
                    this.uploadData.token = JSON.stringify(this.authResult)
                    axios.post(url, this.uploadData)
                        .then(response => {
                            vm.$emit('success', vm.uploadData.name + 'uploaded')
                        })
                        .catch(response => {
                            vm.$emit('error', 'Upload Failed: ' + vm.uploadData.name)
                        })
                },
                    openPicker () {
                        var picker = new google.picker.PickerBuilder().
                            addView(google.picker.ViewId.DOCS_VIDEOS).
                            setOAuthToken(this.oauthToken).
                            setDeveloperKey(this.developerKey).
                            setCallback(this.pickerCallback).
                            build()
                        picker.setVisible(true)
                    },
                    pickerCallback(data) {
                        if (data.action == google.picker.Action.PICKED) {
                            this.uploadData = data.docs[0]
                            bus.$emit('fileAdded', {method: 'drive', filename:this.uploadData.name})
                        }
                    },
                    authWithDrive () {
                        var vm = this
                        gapi.load('auth', function () {
                            window.gapi.auth.authorize(
                                {
                                    'client_id': vm.clientId,
                                    'scope': 'https://www.googleapis.com/auth/drive.readonly',
                                    'immediate': false
                                }, function(authResult) {
                                    if (authResult && !authResult.error) {
                                        vm.oauthToken = authResult.access_token
                                        vm.authResult = authResult
                                    }
                                    vm.openPicker()
                                })
                        })
                        gapi.load('picker', function () {
                            vm.pickerApiLoaded = true
                        })
                    },
            }
    }
    </script>
