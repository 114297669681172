<template>
  <div id="example" class="container">
    <carousel-3d :controls-visible="true" :clickable="true">
      <slide v-for="(video, i) in festivalVideos" :index="i" :key="video.uuid">
        <figure>
          <img :src="'/img/' + video.thumbnail_url + '?w=362&h=272&fit=crop'">
          <figcaption>
            <a :href="'/festivals/' + festival_id + /videos/ + video.uuid">
              <h4 class="text-center"><strong>{{video.name}}</strong></h4>
            </a>
          </figcaption>
        </figure>
      </slide>
    </carousel-3d>
  </div>
</template>

<script>
import axios from 'axios'
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
  props: {
    festival_id: {
      type: String,
      required: true
    }
  },

  components: {
    Carousel3d,
    Slide
  },

  name: 'video-carousel',

  data () {
    return {
      festivalVideos: 7
    }
  },

  created () {
    this.fetchFestivalVideos();
  },

  computed: {
    festivalsUrl() {
      return '/api/festivals/' + this.festival_id + '?include=films'
    }
  },

  methods: {
    fetchFestivalVideos () {
      axios.get(this.festivalsUrl)
        .then((response) => {
          this.festivalVideos = response.data.data.films.data.approved;
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }
};

</script>

<style>
.carousel-3d-container { margin:20px auto 0 auto !important; }
.carousel-3d-container figure { margin:0; }
.carousel-3d-container figcaption {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  bottom: 0;
  position: absolute;
  bottom: 0;
  padding: 15px;
  font-size: 12px;
  min-width: 100%;
  box-sizing: border-box;
}
</style>
