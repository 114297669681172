<template>
  <div>
    <input hidden :name="name" :value="timezone">

    <el-select
      v-model="timezone"
      clearable
      filterable
      remote
      placeholder="Please enter a timezone"
      @clear="fetchTimezones"
      :remote-method="fetchTimezones"
      :loading="loading">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        required: true,
        type: String
      },
      name: {
        required: true,
        type: String
      }
    },

    data () {
      return {
        options: [],
        timezone: '',
        loading: false
      }
    },

    mounted () {
      if (this.value !== null) {
        this.timezone = this.value
      }
      this.fetchTimezones(moment.tz.guess())
    },

    methods: {
      fetchTimezones (query) {
        this.loading = true
        axios.get('/api/timezones?q=' + query)
          .then(response => {
            this.loading = false
            this.options = response.data.data
          })
      }
    }
  }
</script>
