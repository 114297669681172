<template>
    <dropzone ref="vuetndz" id="myThumbnailDropzone"
      :url="url"
      @vdropzone-success="handleSuccess"
      @vdropzone-file-added="handleFileAdded"
      @vdropzone-removed-file="handleFileRemoved"
      @vdropzone-error="handleError"
      acceptedFileTypes='image/png,image/jpg,image/jpeg,image/gif,image/webp'
      :headers="{'X-CSRF-TOKEN': csrfToken}"
      :autoProcessQueue=true
      :maxNumberOfFiles=1
      :maxFileSizeInMB=16
      :useFontAwesome=true>
    </dropzone>
</template>

<script>
import Dropzone from 'vue2-dropzone'

export default {
  components: {
    Dropzone
  },
  props: ['url', 'disabled'],
  data () {
    return {
      tndz: null,
      vuetndz: null,
      csrfToken: window.app.csrfToken
    }
  },
  created () {
    var vm = this
    bus.$on('videoCreated', function (event) {
      if (event.method == 'dropzone') {
        vm.tndz.options.url = '/api/videos/' + event.uuid + '/thumbnails'
      }
    })
  },
  mounted () {
    this.vuetndz = this.$refs.vuetndz
    this.tndz = this.vuetndz.dropzone
  },
  methods: {
    handleSuccess (file, response) {
      bus.$emit('thumnailSuccess', 'File uploaded.')
    },
    handleError () {
      bus.$emit('thumbnailError', 'Upload failed.')
    },
    handleFileAdded () {
      //
    },
    handleFileRemoved () {
      this.$emit('thumbnailRemoved')
    }
  }
}
</script>

<style>
.dropzone .dz-preview {
  margin: 0px !important;
  width: 100% !important;
}
.dz-image {
  width: 100% !important;
  height: 150px !important;
}
</style>
