<template>
    <button :disabled="disabled || btnState == 'btn-danger'"
        :class="btnClass +' '+ btnState" class="btn">
        <span v-show="btnState == 'btn-loading'">
            <i class="fa fa-pulse fa-spinner"></i>
        </span>
        <span v-show="btnState == 'btn-success'">
            <i class="fa fa-check"></i>
        </span>
        <span v-show="btnState == 'btn-danger'">
            <i class="fa fa-close"></i>
        </span>
        <div v-show="
            btnState != 'btn-success'
            && btnState != 'btn-danger'
            && btnState != 'btn-loading'">
            <slot>Save</slot>
        </div>
    </button>
</template>

<script>
    export default {
        props: {
            btnState: {
                default: 'btn-secondary',
            },
            btnClass: {
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        }
    }
</script>

