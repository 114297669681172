<template>
  <div>
    <input hidden :name="name" :value="encodedValue"/>

    <multiselect
      :class="inputClass"
      :label="label"
      :track-by="trackBy"
      v-model="val"
      @input="handleChange"
      :options="options"
      :is-loading="isLoading"
      :multiple="true"
      @search-change="asyncFind"
      placeholder="Select Multiple"
      ></multiselect>

  </div>
</template>

<script>
import multiselect from 'vue-multiselect'

export default {
  components: { multiselect },

  props: ['value', 'name', 'url', 'trackBy', 'label', 'inputClass'],

  data () {
    return {
      encodedValue: '',
      options: [],
      isLoading: false,
      val: []
    }
  },

  created () {
    this.asyncFind()
    this.val = JSON.parse(this.value)
    this.encodedValue = this.value
  },

  methods: {
    handleChange (e) {
      this.encodedValue = JSON.stringify(e)
    },

    asyncFind (q = '') {
      this.isLoading = true
      axios.get(this.url + '?q=' + q)
        .then(res => {
          this.options = res.data
          this.isLoading = false
        })
    }
  }
}
</script>
