<template>
  <div>
    <div v-show="activeCard">
      <label class="d-block label">Active Card</label>
      <div class="d-flex align-items-center">
        <i class="fa fa-2x" :class="activeCard.class"></i>
        <span class="pl-2">****{{ activeCard.card_last_four }}</span>
      </div>
    </div>

    <!-- stripe elements -->
    <form @submit.prevent="tokenize" id="payment-form">
      <div class="form-row my-3">
        <label class="mb-3 label" for="card-element">
          <span v-if="activeCard">Update </span>
          <span v-else>Add </span>
          credit or debit card
        </label>
        <div class="form-control" id="card-element">
          <!-- a Stripe Element will be inserted here. -->
        </div>
        <!-- Used to display Element errors -->
        <div class="text-danger" id="card-errors"></div>
      </div>

      <!-- tokenize for adding a subscription -->
      <anibutt
        :state="state"
        :disabled="disabled"
        class="col-4 mx-auto"
        btnClass="btn-block btn-primary">
        <span v-if="!activeCard">Add Card</span>
        <span v-else>Update Card</span>
      </anibutt>
    </form>
  </div>
</template>

<script>
  /* global elements stripe */
  import axios from 'axios'
  import anibutt from '../../utilities/anibutt.vue'

  export default {
    components: { anibutt },
    name: 'card-form',

    data () {
      return {
        subscriptions: [],
        stripe: {},
        stripe_token: '',
        elements: {},
        card: {},
        submitting: false,
        activeCard: false,
        disabled: true,
        state: 'none'
      }
    },

    created () {
      this.fetchCard()
      this.elements = this.stripe.elements
      this.card = elements.create('card')
    },

    mounted () {
      this.$nextTick(() => {
        this.card.mount('#card-element')
        // Handle real-time validation errors from the card Element.
        const $v = this
        this.card.addEventListener('change', (event) => {
          const displayError = document.getElementById('card-errors')
          if (event.error) {
            $v.disabled = true
            displayError.textContent = event.error.message
          } else {
            $v.disabled = false
            $v.state = 'none'
            displayError.textContent = ''
          }
        })
      })
    },

    methods: {
      fetchCard () {
        axios.get('/api/card')
          .then(this.parseActiveCard)
      },

      parseActiveCard (response) {
        const lastFour = response.data.data.card_last_four
        const cardBrand = response.data.data.card_brand
        if (lastFour && cardBrand) {
          this.activeCard = {
            class: { ['fa-cc-' + cardBrand.toLowerCase()]: true },
            card_last_four: lastFour,
            card_brand: cardBrand
          }
          this.$emit('gotCard', this.activeCard)
        } else {
          this.activeCard = false
        }
      },

      tokenize () {
        this.state = 'processing'
        const $v = this
        stripe.createToken(this.card).then((result) => {
          if (result.error) {
            // Inform the user if there was an error
            var errorElement = document.getElementById('card-errors')
            errorElement.textContent = result.error.message
            $v.state = 'error'
          } else {
            $v.updateCard(result.token.id)
          }
        })
      },

      updateCard (token) {
        axios.post('/api/card', { stripe_token: token })
          .then((r) => {
            this.state = 'success'
            this.parseActiveCard(r)
            window.location = document.referrer
          })
          .catch((e) => {
            console.log(e)
            this.state = 'error'
          })
      }
    }
  }
</script>

<style>
  /**
  * The CSS shown here will not be introduced in the Quickstart guide, but shows
  * how you can use CSS to style your Element's container.
  */
.StripeElement {
  background-color: white;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid whitesmoke;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>
