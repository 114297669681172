<template>
  <div class="container">
    <div v-show="createMode">
      <h1 class="pb-1">Upload Video</h1>
      <video-upload :url="uploadUrl" :disabled="fileAdded" @success="handleUploadSuccess" @fileRemoved="handleFileRemoved"></video-upload>
      <p class="text-center pt-2">&dash; OR &dash;</p>
      <div class="row pt-1">
        <div class="col-sm-6 col-lg-4 col-xl-3 offset-lg-2 offset-xl-3 pb-1">
          <dropbox-upload :url="uploadUrl" :disabled="fileAdded" @success="handleUploadSuccess" @fileRemoved="handleFileRemoved">
            <i class="fa fa-dropbox"></i> Choose from Dropbox
          </dropbox-upload>
        </div>
        <div class="col-sm-6 col-lg-4 col-xl-3 pb-1">
          <google-upload :url="uploadUrl" :disabled="fileAdded" :developerKey="drive_developer_key" :clientId="drive_client_id" @success="handleUploadSuccess" @fileRemoved="handleFileRemoved">
            <i class="fa fa-google"></i> Choose from Google
          </google-upload>
        </div>
      </div>
      <div>
        <p class="text-center pt-2">
          <i class="fa fa-warning"></i> Upload taking too long? Having trouble with your upload? Try Dropbox or Google Drive for faster upload speeds.
        </p>
      </div>
      <div v-show="uploadSuccess" class="alert alert-success text-center">
        <b>File Added:</b> "{{ filename }}" You may edit details and navigate away from this page. You will be notified when your video has finished processing.
      </div>
      <div v-show="fileAdded && !uploadSuccess" class="text-center container">
        <i class="fa fa-spin fa-spinner fa-3x"></i>
        <h4 class="pt-1">
          Uploading Video...
        </h4>
        <p>
          Wait for upload to complete before leaving this page.
        </p>
      </div>
    </div>
    <div class="row" v-show="fileAdded">
      <div class="col-lg-9">
        <div class="card">
          <h4 class="card-header">Video Details</h4>
          <div class="card-body">
            <div class="form-group">
              <label class="form-control-label">Film Name*</label>
              <input @input="handleFormChange" v-model='video.name' type="text" class="form-control" :class="{'is-invalid': errors.name}"></input>
              <div v-if="errors.name" class="invalid-feedback">
                {{ errors.name[0] }}
              </div>
            </div>
            <div class="form-group">
              <label class="form-control-label">Filmmaker Name</label>
              <input @input="handleFormChange" v-model='video.filmmaker' type="text" class="form-control" :class="{'is-invalid': errors.filmmaker}"></input>
              <div v-if="errors.filmmaker" class="invalid-feedback">
                {{ errors.filmmaker[0] }}
              </div>
            </div>
            <div class="form-group">
              <label class="form-control-label">Year Completed</label>
              <input @input="handleFormChange" v-model='video.year_completed' type="date" class="form-control" :class="{'is-invalid': errors.year_completed}"></input>
              <div v-if="errors.year_completed" class="invalid-feedback">
                {{ errors.year_completed[0] }}
              </div>
            </div>
            <div class="form-group" :class="{'invalid' : errors.categories}">
              <label class="form-control-label">Categories*</label>
              <multiselect
                :class="{'formError' : errors.categories }"
                label="name"
                track-by="id"
                v-model="selectedCategories"
                @input="setCategories"
                :options="categories"
                :multiple="true"
                placeholder="Select A Category"
                ></multiselect>
              <div v-if="errors.categories" class="form-label">
                {{ errors.categories[0] }}
              </div>
            </div>
            <div class="form-group" v-if="createMode || Array.isArray(video.tags)">
              <label class="form-control-label">Tags</label>
              <tagger name="tags" :value="(video.tags || []).join(',')"></tagger>
            </div>
            <div class="form-group">
              <label class="form-control-label">Language</label>
              <select class="form-control" :class="{'is-invalid': errors.language_id}" @change="handleFormChange" v-model="video.language_id">
                <option v-for="language in languages" :value="language.id">
                  {{ language.name }}
                </option>
              </select>
              <div v-if="errors.language_id" class="invalid-feedback">
                {{ errors.language_id[0] }}
              </div>
            </div>
            <div class="form-group" v-if="video.language_id > 1">
              <input @change="handleFormChange" type="checkbox" v-model="video.translate"> Would you like English Sub-titles?
            </div>
            <div class="form-group">
              <label class="form-control-label">Description*</label>
              <textarea rows="3" @input="handleFormChange" v-model='video.description' class="form-control" :class="{'is-invalid': errors.description}"></textarea>
              <div v-if="errors.description" class="invalid-feedback">
                {{ errors.description[0] }}
              </div>
            </div>
            <div class="form-group">
              <label class="form-control-label">Thumbnail</label>

              <div class="form-group">
                <thumbnail-upload :url="thumbnailUploadUrl" :disabled="fileAdded" :class="{'is-invalid': errors.thumbnail}"></thumbnail-upload>
              </div>

              <multiselect
                v-model="selectedThumbnail"
                placeholder="Select Thumbnail"
                label="name"
                track-by="id"
                @input="setThumbnail"
                :options="thumbnails"
                :option-height="104"
                :custom-label="customThumbnailLabel"
                :show-labels="false">
                <template slot="option" slot-scope="props">
                  <img class="option__image" :src="props.option.image_url" alt="Thumbnail">
                  <div class="option__desc">
                    <span class="option__title">{{ props.option.name }}</span>
                  </div>
                </template>
              </multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3" v-if="showSettings">
        <div class="card">
          <h4 class="card-header">Video Settings</h4>
          <div class="card-body">
            <div class="form-group">
              <label class="form-control-label">Privacy*</label>
              <div class="radio-inline">
                <input  :class="{'is-invalid': errors.privacy}" @change="handleFormChange" type="radio" name="privacy" v-model="video.privacy" value="public"> Public
              </div>
              <div class="radio-inline">
                <input @change="handleFormChange" type="radio" name="privacy" v-model="video.privacy" value="private"> Private
              </div>
              <div v-if="errors.privacy" class="invalid-feedback">
                {{ errors.privacy[0] }}
              </div>
            </div>
            <div class="form-group">
              <label class="form-control-label">Payment Settings*</label>
              <div class="radio-inline">
                <input @change="handleFormChange" :class="{'is-invalid': errors.payment_type}" type="radio" name="payment_type" v-model="video.payment_type" value="free"> Free
              </div>
              <div class="radio-inline">
                <input @change="handleFormChange" type="radio" name="payment_type" v-model="video.payment_type" value="donations"> Accept Donations
              </div>
              <div class="radio-inline">
                <input @change="handleFormChange" type="radio" name="payment_type" v-model="video.payment_type" value="paid"> Paid
              </div>
              <div v-if="errors.payment_type" class="invalid-feedback">
                {{ errors.payment_type[0] }}
              </div>
            </div>
            <label class="sr-only" for="exampleInputAmount">Amount (in dollars)</label>
            <div v-show="isPaid">
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-addon"><i class="fa fa-dollar"></i></div>
                  <input v-model="video.price" :class="{'is-invalid': errors.price}" @change="handleFormChange" type="text" class="form-control" placeholder="Price" pattern="^\d*(\.\d{2}$)?" size="4" title="CDA Currency Format - no dollar sign and no comma(s) - cents (.##) are optional" />
                </div>
                <div v-if="errors.price" class="invalid-feedback">
                  {{ errors.price[0] }}
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-addon">Free Views</div>
                  <input v-model="video.free_views" @change="handleFormChange" class="form-control" placeholder="0">
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="form-control-label">Share Settings</label>
              <div class="checkbox-inline">
                <input @change="handleFormChange" type="checkbox" :disabled="isPrivate" v-model="video.shareable"> Allow Sharing
              </div>
            </div>
            <fieldset class="form-group">
              <label class="form-control-label">Clip Settings</label>
              <div class="checkbox-inline">
                <input @change="handleFormChange" type="checkbox" :disabled="isPaid || isPrivate" v-model="video.clipable"> Allow Clips
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      <div v-if="errors && Object.keys(errors).length" class="container align-items-center mt-2">
        <div class="alert alert-danger text-center" role="alert">
          Please correct the errors above.
        </div>
      </div>
      <div @click="updateVideo" class="col-lg-6 offset-lg-3 mt-2">
        <anibutt :disabled="!fileAdded" :btnState="btnState" btnClass="btn-block btn-primary btn-lg">
          Save Details
        </anibutt>
      </div>
      <div v-if="festival_uuid && detailsSaved" class="container align-items-center mt-2">
        <div class="alert alert-success text-center" role="alert">
          Video details saved! <a :href="submitFilmUrl" class="alert-link">Submit another</a> or <a href="/account/videos" class="alert-link">go to my videos</a>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import anibutt from '../utilities/anibutt.vue'
import dropboxUpload from './dropboxUpload.vue'
import googleUpload from './googleUpload.vue'
import multiselect from 'vue-multiselect'
import videoUpload from './videoUpload.vue'
import thumbnailUpload from './thumbnailUpload.vue'

export default {
  props: {
    drive_developer_key: {
      type: String,
      required: true
    },
    drive_client_id: {
      type: String,
      required: true
    },
    video_uuid: {
      type: String,
      required: false
    },
    festival_id: {
      type: String,
      required: false
    },
    festival_uuid: {
      type: String,
      required: false
    }
  },
  //TODO watch for form update and have an modified bool
  // tell user if they did not save something and they try to leave
  components: {
    videoUpload,
    thumbnailUpload,
    googleUpload,
    dropboxUpload,
    anibutt,
    multiselect
  },
  data () {
    return {
      modified: true,
      fileAdded: false,
      detailsSaved: false,
      createMode: false,
      categories: [],
      selectedCategories: [],
      selectedThumbnail: null,
      languages: [],
      errors: {},
      uploadError: false,
      uploadSuccess: false,
      uploadUrl: '/api/videos/upload',
      thumbnailUploadUrl: '/api/videos/upload',
      btnState: false,
      filename: '',
      thumbnails: [],
      video: {
        uuid: null,
        name: null,
        filmmaker: null,
        year_completed: null,
        categories: [],
        language_id: 1,
        translate: false,
        description: null,
        privacy: 'public',
        payment_type: 'free',
        price: 0,
        free_views: 0,
        shareable: true,
        clipable: true,
        thumbnail_url: null,
        tags: null
      }
    }
  },
  created () {
    this.fetchCategories()
    this.fetchLanguages()

    if (this.video_uuid) {
      this.video.uuid = this.video_uuid
      this.fetchVideo()

      this.thumbnailUploadUrl = '/api/videos/' + this.video.uuid + '/thumbnails'
    } else {
      this.createMode = true
    }

    var vm = this
    bus.$on('fileAdded', function (event) {
      vm.createVideo(event)
    })
    bus.$on('thumnailSuccess', function (event) {
      vm.fetchThumbnails()
    })
  },
  computed: {
    showSettings () {
      return this.festival_uuid === undefined
    },

    videoUrl() {
      return '/api/videos/' + this.video.uuid
    },
    thumbnailUrl() {
      return '/api/videos/' + this.video.uuid + '/thumbnails'
    },
    submitFilmUrl() {
      return '/festivals/' + this.festival_uuid + '/submit-film'
    },
    isPrivate() {
      return this.video.privacy == 'private'
    },
    isPaid() {
      return this.video.payment_type == 'paid'
    }
  },
  methods: {
    fetchCategories() {
      axios.get('/api/categories').then(response => {
        this.categories = response.data.data
      }, response => {
        this.errors = response.data.data
      })
    },
    fetchLanguages () {
      axios.get('/api/languages').then(response => {
        this.languages = response.data.data
      }, response => {
        this.errors = response.data.data
      })
    },
    fetchVideo () {
      axios.get(this.videoUrl).then(response => {
        var video = response.data.data
        this.video.name = video.name
        this.selectedCategories = video.categories.data
        this.video.categories = this.selectedCategories.map(function (a) {return a.id})
        this.video.filmmaker = video.filmmaker
        this.video.language_id = video.language_id
        this.video.translate = video.translate
        this.video.description = video.description
        this.video.privacy = video.privacy
        this.video.payment_type = video.payment_type
        this.video.price = video.price
        this.video.free_views = video.free_views
        this.video.shareable = video.shareable
        this.video.clipable = video.clipable
        this.video.tags = video.tags

        // TODO Replicate with hide upload too
        this.fileAdded = true
        this.createMode = false

        this.fetchThumbnails()
      })
    },
    fetchThumbnails () {
      axios.get(this.thumbnailUrl).then(response => {
        var selected
        var thumbs = response.data.data
        this.thumbnails = thumbs

        selected = thumbs.find((i) => {
          return i.selected
        })

        this.video.thumbnail_url = selected.id
        this.selectedThumbnail = { 'id': selected.id, 'name': selected.name }
      })
    },
    createVideo (event) {
      this.filename = event.filename
      axios.post('/api/videos', { festival_id: this.festival_id })
        .then(response => {
          this.video.uuid = response.data.uuid
          this.thumbnailUploadUrl = '/api/videos/' + this.video.uuid + '/thumbnails'
          bus.$emit('videoCreated', {
            method: event.method,
            uuid: this.video.uuid
          })
          this.fileAdded = true
        }).catch(error => {
          this.btnState = false
          if(error.response) {
            this.errors = error.response.data.errors
          }
        })
    },
    updateVideo () {
      this.btnState = 'btn-loading'
      const tagsString = document.querySelector('input[name="tags"]').value
      this.video.tags = tagsString.length ? tagsString.split(',') : []
      axios.put(this.videoUrl, this.video)
        .then(response => {
          this.modified = false
          this.detailsSaved = true
          this.btnState = 'btn-success'
        }).catch(error => {
          this.btnState = false
          if(error.response) {
            this.errors = error.response.data.errors
          }
        })
    },
    customThumbnailLabel ({ id, name }) {
      return `${name}`
    },
    setCategories () {
      this.video.categories = this.selectedCategories.map(function (a) {return a.id})
    },
    setThumbnail (thumbnail) {
      this.video.thumbnail_url = thumbnail.id
    },
    handleUploadError () {
      this.uploadError = true
    },
    handleUploadSuccess () {
      this.uploadSuccess = true
    },
    handleFileRemoved () {
      this.fileAdded = false
      this.uploadSuccess = false
    },

    handleFormChange () {
      this.hideErrors()

      //reset dependant values
      this.video.free_views = this.isPaid ?
        this.video.free_views : 0
      this.video.price = this.isPaid ?
        this.video.price : 0
      this.video.shareable = !this.isPrivate ?
        this.video.shareable : false
      this.video.clipable = !(this.isPrivate || this.isPaid) ?
        this.video.clipable : false
    },
    hideErrors() {
      this.btnState = false
      this.errors = {}
    }
  }
}
</script>

<style>
.option__image {
  max-height: 104px;
}
</style>
