<template>
  <button @click="openPicker" class="btn btn-block btn-social btn-dropbox">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
      multiselect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadData: {},
    }
  },
  created() {
    var vm = this
    bus.$on('videoCreated', function (event) {
      if (event.method == 'dropbox') {
        vm.upload('/api/videos/upload/' + event.uuid)
      }
    })
  },
  methods: {
    upload(url) {
      var vm = this
      axios.post(url, this.uploadData)
        .then(response => {
          vm.$emit('success', vm.uploadData.name + 'uploaded')
        })
        .catch(response => {
          vm.$emit('error', 'Upload Failed: ' + vm.uploadData.name)
        })
    },
    openPicker () {
      var vm = this
      Dropbox.choose({
        success: function(files) {
          vm.uploadData = files[0]
          vm.uploadData.method = "dropbox"
          bus.$emit('fileAdded', {method: 'dropbox', filename:files[0].name})
        },
        linkType: "direct",
        multiselect: false,
        extensions: ['video']
      })
    },
  }
}
</script>
