<template>
    <dropzone ref="vuedz" id="myVueDropzone"
      :url="url"
      @vdropzone-success="handleSuccess"
      @vdropzone-file-added="handleFileAdded"
      @vdropzone-removed-file="handleFileRemoved"
      @vdropzone-error="handleError"
      acceptedFileTypes='video/*'
      :timeout=1800000000
      :autoProcessQueue=false
      :maxNumberOfFiles=1
      :maxFileSizeInMB=10000
      :useFontAwesome=true>
    </dropzone>
</template>

<script>
import Dropzone from 'vue2-dropzone'

export default {
  components: {
    Dropzone
  },
  props: ['url', 'disabled'],
  data() {
    return {
      dz: null,
      vuedz: null
    }
  },
  created() {
    var vm = this
    bus.$on('videoCreated', function (event) {
      if (event.method == 'dropzone') {
        vm.upload('/api/videos/upload/' + event.uuid)
      }
    })
  },
  mounted() {
    this.vuedz = this.$refs.vuedz
    this.dz = this.vuedz.dropzone
  },
  methods: {
    uploadAlert () {
      return true
    },
    upload(url) {
      this.dz.options.url = url
      this.dz.options.headers = {'X-CSRF-TOKEN': window.app.csrfToken}
      this.vuedz.processQueue()
    },
    handleSuccess() {
      this.$emit('success', 'File uploaded.')
      window.onbeforeunload = function () {
        return null
      }
    },
    handleError() {
      this.$emit('error', 'Upload failed.')
    },
    handleFileAdded() {
      // Stop folks from navigating away without a warning
      window.onbeforeunload = this.uploadAlert
      //if there is already a file, replace it
      if (this.dz.files[1] != null) {
          this.dz.removeFile(this.dz.files[0])
      }
      var filename = this.dz.files[0].name
      bus.$emit('fileAdded', {
        method: 'dropzone',
        filename: filename
      })
    },
    handleFileRemoved() {
      this.$emit('fileRemoved')
    }
  }
}
</script>

<style>
.dropzone .dz-preview {
  margin: 0px !important;
  width: 100% !important;
}
.dz-image {
  width: 100% !important;
  height: 150px !important;
}
</style>
